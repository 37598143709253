/*
 * Globals
 */

/* Custom default button */
.btn-light,
.btn-light:hover,
.btn-light:focus {
  color: #333;
  text-shadow: none; /* Prevent inheritance from `body` */
}
/*
 * Base structure
 */

body {
  /*text-shadow: 0 .05rem .1rem rgba(0, 0, 0, .5); 
  box-shadow: inset 0 0 5rem rgba(0, 0, 0, .5);*/
  background-color: #ffd60a;
  /* align center */
}

.mx-auto{
  margin-left: auto !important;
  margin-right: auto !important;
  margin: auto !important;
  padding: 10px;
}


button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 15px;
  line-height: inherit;
}
.bg-yellow{
  background-color: #ffd60a;
}

.container {
  max-width: 42em;
  font-family: "Sharp Grotesk";
}


@font-face {
  font-family: "Cross Town Personal Use";
  src: url("./assets/fonts/CrossTownPersonalUse.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Cinderheart";
  src: url("./assets/fonts/Cinderheart.otf");
  font-weight: 400;
}
@font-face {
  font-family: "Sharp Grotesk";
  src: url("./assets/fonts/SharpGroteskLight25.ttf");
  font-weight: 200;
}
@font-face {
  font-family: "Sharp Grotesk";
  src: url("./assets/fonts/SharpGroteskMedium25.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Sharp Grotesk";
  src: url("./assets/fonts/SharpGroteskSmBold25.ttf");
  font-weight: 800;
}
@font-face {
  font-family: "Amazon Ember";
  src: url("./assets/fonts/AmazonEmberBold.ttf");
  font-weight: 700;
}

main {
  
  background-color: #ffd60a;
 /* 
  text-align: center;
  font-size: 64px;
  color: #000;
  font-family: "Sharp Grotesk";
  */
}

main p, label{
  font-size: 18px;
}

header {
  overflow: hidden;
  background-image: url('./assets/images/gol-home-bg2.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  min-height: 100vh;
}

.parent{
  position: relative;
  border-width: 5px;
  border-style: solid;
  border-color: #000;
}

.parent img {
  border-image: url('./assets/images/gang-frame.png') 22 16 16 22;
  background: #000;
  width: 100%;
}

.location-text {
  padding-left: 14px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
  width: fit-content;
}

.location-text h2 {
  font-size:64px;
  padding-top: 6px;
  display: block;
  line-height: 1.2;
  margin-bottom: -22px;
}

.hash-text {
  text-align: left;
  text-align: left;
  background-color: black;
  width: fit-content;
  padding-left: 19px;
  padding-right: 7px;
  margin-top: -20px;
}

.hash-text span {
  text-transform: capitalize;
  font-size: 11px;
  font-family: "Sharp Grotesk";
  white-space: nowrap;
}

.location-of p{
    font-size: 29px;
    padding-top: 10px;
    width:1px;
    word-wrap: break-word;
    white-space:pre-wrap;
    line-height: 1em;
}

.the-gang-child {
  position: absolute;
  top: 230px;
  left: 1px;
  width: 90%;
  z-index: 1;
}

.gangtext{
  position: relative;
  text-align: left;
  padding-left: 21px;
  padding-top: 34px;
  padding-right: 15px;
  width:138px;
  height: 50px;
  background-color: #000;
  color: #fff;
  z-index: 0 !important;
  font-family: "Cross Town Personal Use";
  font-size: 64px;
  line-height: 0.3;
}


.location{
  text-align: left;
  font-family: "Cross Town Personal Use";
  padding-left: 20px;
  padding-right: 15px;
  color: #ffd60a;
  width: fit-content;
  padding-top: 5px;;
} 

.hashtag {
  top: 132px;
  left: 22px;
  font-size: 11px;
  font-family: "Sharp Grotesk";
  position: inherit;
}

.sub-head h1{
  font-size: 22px;
  color: #000;
  font-family: "Sharp Grotesk";
  text-transform: uppercase;
  text-shadow: none; /* Prevent inheritance from `body` */
}

.sub-head .lead{
  font-size: 16px;
  font-family: "Sharp Grotesk";
  text-shadow: none; /* Prevent inheritance from `body` */
  padding-bottom: 20px;
}

.btn-get-started {
    font-size: 25px;
    font-weight: 300;
    text-transform: uppercase;
    font-family: "Sharp Grotesk";
    text-align: center;
    border-radius: 0px;
    border: none;
    padding: 15px 35px;
    color: #000;
}


.play-now {
    font-size: 25px;
    text-transform: uppercase;
    font-family: "Sharp Grotesk";
    color: #fff;
    text-align: center;
    border-radius: 0px;
    border: none;
    padding: 15px 0;
}

.play-now:hover {
  background-color: #000;
  color: #fff;
}

.btn-get-started:hover {
    color: #ffd60a;
    background-color: #000;
}

.how-to-play {
  font-size: 25px;
  text-transform: uppercase;
  border-radius: 0;
  padding: 15px;
  border: 5px solid #000;
  box-sizing: border-box;
  font-family: "Sharp Grotesk";
  color: black;
  background-color: #f3d0207d;
}

.how-to-play:hover {
  color: #fff;
  background-color: #000;
}

.btn_300 {
  width: 300px;
}
.btn_300:hover {
  color: #fff;
}

.btn-upload-picture {
  font-size: 25px;
  font-weight: 300;
  text-transform: uppercase;
  font-family: "Sharp Grotesk";
  text-align: center;
  border-radius: 0px;
  border: none;
  padding: 15px 35px;
  color: #ffd60a;
}

.btn-re-upload-picture {
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  font-family: "Sharp Grotesk";
  text-align: center;
  border-radius: 0px;
  border: 3px solid black;
  padding: 8px 35px;
}

.btn-prime {
  font-size: 25px;
  font-weight: 300;
  font-family: "Sharp Grotesk";
  text-transform: uppercase;
  text-align: center;
  border-radius: 0px;
  border: none;
  padding: 23px 53px;
  color: #fff;
  background-color: #1A98FF;
}

.btn-prime:hover {
  color: #fff;
  background-color: #1479AF;
}

.main-content {
  overflow: hidden;
  background-image: url('./assets/images/frame-14@3x.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.content-item{
  border-bottom: 1px solid #000;
  padding: 50px;
}
.circle{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.number{
  font-size: 30px;
  color: #ffd60a;
  font-family: Inter;
  font-weight: 700;
}

.content h3{
  font-size: 20px;
  color: #000;
  text-transform: uppercase;
  font-family: "Sharp Grotesk";
}


.footer-logo{
  font-size: 24px;
  text-align: center;
  color: #000;
  font-family: "Sharp Grotesk";
}

.footer-logo img{
  height: 32px;
}


/* Page 2 */

.header-description-section {
  border-radius: 10px;
  background-color: #f3d0207a;
  border: 2px dashed #000;
  padding: 17px 21px;
  align-items: center;
  justify-content: center;
}

.decsription-text {
  line-height: 23px;
  text-align: center;
  font-size: 14px;
  color: #000;
  font-family: "Sharp Grotesk";
  font-weight: 200;
}

.section-head h2{
  text-align: left;
  font-size: 57px;
  font-family: "Cross Town Personal Use";
  color: black;
}

.location-input{
  font-family: "Sharp Grotesk";
  font-size: 24px;
  font-weight: 300;
  background-color: #fff;
  height: 57px;
  border-radius: 0;
  padding: 0 20px;
  border: 1px solid black;
  float: left;
  width: calc(100% - 82px);
  margin-right: 5px;
}

.location-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #B8B8B8;
  opacity: 1; /* Firefox */
  font-family: "Sharp Grotesk";
  font-size: 24px;
  font-weight: 200;
}

.is-invalid::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: red;
  opacity: 1; /* Firefox */
  font-family: "Sharp Grotesk";
  font-size: 24px;
  font-weight: 200;
}

.location-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #B8B8B8;
  font-family: "Sharp Grotesk";
  font-size: 24px;
  font-weight: 200;
}

.location-input::-ms-input-placeholder { /* Microsoft Edge */
  color: #B8B8B8;
  font-family: "Sharp Grotesk";
  font-size: 24px;
  font-weight: 200;
  float: right;
  margin-left: 5px;

}

.btn-check-location {
  border: 0;
  border-radius: 0;
  background-color: #fff;
  color: #000;
  font-size: 24px;
  height: 57px;
  border-radius: 0;
  padding: 0 20px;
  float: right;
}

.is-invalid + .invalid-feedback {
  display: block;
}

.invalid-feedback {
  /*display: block;*/
  margin-top: 0.25rem;
  font-family: "Sharp Grotesk";
  font-size: 15px;
  color: #FF0000;
  background-color: #fff;
  border:#FF0000 1px solid;
  padding: 8px 0;
}


.rectangle-parent1 {
  width: 399px;
  height: 343px;
  color: rgba(0, 0, 0, 0.3);
}

.instance-child7 {
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 15px;
  background: linear-gradient(
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    #dfb540;
  border: 2px dashed #000;
  box-sizing: border-box;
}

.take-a-portrait-photo-parent2 {
  position: absolute;
  height: 55.1%;
  width: 36.34%;
}

.take-a-portrait5 {
  position: absolute;
  width: 100%;
  line-height: 147.02%;
  display: inline-block;
}

.material-symbolsscreen-lock-p-icon10 {
  position: absolute;
  height: 73.02%;
  width: 95.17%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.image-upload-box {
  border-radius: 15px;
  background: linear-gradient( rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2) ), #FFDD32;
  border: 3px dashed #000;
  box-sizing: border-box;
}

.image-upload-box-white {
  border-radius: 15px;
  background: linear-gradient( rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2) ), #fff;
  border: 2px dashed #000;
  box-sizing: border-box;
}

.image-upload-box-inner-white p {
  font-size: 13px;
  line-height: 23px;
  text-align: center;
  color: #000;
  font-family: "Sharp Grotesk";
}

.vertical-line {
  border-left: 1px solid #ccc;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

.vertical-line::after {
  content: "or";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #000;
  padding: 0 8px;
  font-size: 0.75rem;
}



.image-upload-box-inner p {
  font-size: 13px;
  line-height: 23px;
  text-align: center;
  color: rgba(0, 0, 0, 0.3);
  font-family: "Sharp Grotesk";
}

.image-upload-box-inner img {
  color: rgba(0, 0, 0, 0.3);
}

/* if device screen is mobile */

.upload-box-middle{
  font-size: 1rem; 
  font-weight: 700; 
  font-family: "Sharp Grotesk";
  line-height: 24px; 
  height: 45%; 
  display: flex; 
  flex-direction: column; 
  justify-content: space-between; 
  align-items: center; 
  margin-left: 9px; margin-top: 3px;
}

.upload-box-line{
  width: 1px; 
  height: 56px; 
  object-fit: cover;
  color: #D9D9D9;
}

.sub-head-restart h1 {
  font-family: "Sharp Grotesk";
  font-size: 20px;
  text-transform: uppercase;
}

.download-image{
  font-family: "Sharp Grotesk";
  font-size: 20px;
  color: #000;
  font-weight: 100;
  background: transparent;
  border: none;
  font-weight: 500;
  text-transform: uppercase;
}

.crop-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
}

.controls {
  position: absolute;
  bottom: 20px;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  height: 40px;
  display: flex;
  align-items: center;
}

.slider {
  padding: 22px 0px;
}

.zoom-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 2px;
  border-top: 5px solid #fff;
  border-bottom: 5px solid #fff;
  background: #3f51b5;
  width: 100%;
}

.zoom-range::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.zoom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.controls:hover input[type='range']::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  border-radius: 50%;
}

.controls:hover input[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
}

.sub-head {
  padding: 40px 0;
}

#how-to-play {
  padding: 40px 0;
}

.gang_box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./assets/images/gang_image_box.png');
  background-size: cover;
  z-index: 1
}

.square {
  width: 100%;
  height: 0;
  padding-top: 100%;
  position: relative;
}
.square_content {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 72%;
  width: 100%;
  height: 100%;
  background-image: url('./assets/images/gang_image_box.png');
  background-size: cover;
}

.gangtext p{
  font-size: 56px;
}

@media only screen and (max-width: 576px) {
  .the-gang-child {
    top: 160px;
    left: 0;
  }

  .parent{
    border-width: 6px;
  }

  .content-item{
    border-bottom: 1px solid #000;
    padding: 20px;

    /* additions */
    height: 136px;
    display: flex; /* Use Flexbox */
    align-items: center; /* Vertically center child elements */
    justify-content: center; /* Horizontally center child elements */
  }

  .content-item .row {
    display: flex;
    align-items: center; 
    justify-content: center; 
  }

  .number{
    font-size: 30px;
  }

  .circle{
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content h3{
    font-size: 16px;
    color: #000;
    text-transform: uppercase;
    font-family: "Sharp Grotesk";
  }

  .section-head h2{
    padding: 10px 8px;
    color: #000;
  }

  .image-upload-box-inner .image{
    width: 95px;
  }

  .image-upload-box-inner p {
    font-size: 10px;
    line-height: 18px;
  }

  .btn-prime {
    font-size: 18px;
    padding: 14px 25px 20px 25px
  }
  
  .btn-upload-picture {
    font-size: 20px;
    padding: 18px 35px;
  }

  .location-text h2 {
    font-size:56px;
  }
  
  .location-of p{
      font-size: 25px;
  }

  .square_content{
   padding-top: 58%;
  }
  
}

.social-links button {
  font-size: 48px;
  display: inline-block;
  background: #000;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  text-align: center;
  width: 80px;
  height: 65px;
  transition: 0.3s;
  border: 0;
}

 .social-links button:hover {
  background: #000;
  color: #fff;
  text-decoration: none;
}

.how-to-box {
  border: 4px solid #000;
  padding: 20px 10px;
  text-align: left;
  margin: 30px 0 60px;
  font-family: "Sharp Grotesk";
  background-color: #f3d020ba;
}

.how-to-box li {
  margin-bottom: 10px;
}

.how-to-page {
  background-image: url('./assets/images/gol-new-bg.jpg');
  background-size: cover;
  height: 100%;
}

.home-page {
  background-image: url('./assets/images/gol-home-bg.jpg');
  background-size: cover;
  background-position: bottom;
  height: 100%;
}
.home-page .play-now{
  margin-bottom: 30px;
}


.how-to-page h3, .home-page h3 {
  margin: 20px 0 0;
}

h3.comingout {
  margin: 20px 0;

}

html, body, #root, #root > div {
  height: 100%;
  min-height: 100%;
}

main {
  min-height: 100%;
}

.inverted-text {
  filter: invert(100%);
}

.instruction-box  {
  padding: 20px;
  margin: 20px 0;
  border: 2px dashed black;
  border-radius: 10px;
  background-color: #f3d020db;
}

.instruction-box .btn-upload-picture {
  margin-top: 30px;
  background-color: black;
}

.download-bottom {
  border-bottom: 3px solid black;
}

/* iPhone 11 Pro fixes */

@media only screen and (max-width: 768px) {
  /* CSS styles for devices with a maximum width of 768px */

  .instruction-box img {
    width: 100%;
    height: auto;
  }

  .gangtext {
    width: 117px;
  }
  .gangtext p {
    font-size: 45px;
  }
  .location-text {
    padding-left: 10px;
  }
  .location-text h2 {
    font-size: 45px;
  }
  .location-of p {
    font-size: 18px;
  }
  .hash-text {
    margin-top: -16px;
  }

}

@media only screen and (device-width: 375px) {
  .btn-upload-picture {
    font-size: 19px;
  }
  .how-to-page h3, h3.comingout {
    font-size: 20px;
  }
  .how-to-box li {
    font-size: 14px;
}
}
